export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An RFC-3339 compliant Full Date Scalar */
  Date: { input: any; output: any; }
  /** A slightly refined version of RFC-3339 compliant DateTime Scalar */
  DateTime: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type BodyType = {
  __typename?: 'BodyType';
  id: Scalars['ID']['output'];
};

/**  This type represents pseudo group collecting base body types from code book for use on a client. */
export type BodyTypeGroup = {
  __typename?: 'BodyTypeGroup';
  /**  Identifiers of body types from codebook included in this group */
  includedBodyTypes?: Maybe<Array<BodyType>>;
  /**  Unique string identifier like "combi", "sedan" etc. */
  name: Scalars['String']['output'];
};

export type Car = {
  __typename?: 'Car';
  batteryCapacity?: Maybe<Scalars['Float']['output']>;
  bodyType: CodeBookItem;
  /**
   * null => not available or set as hidden in DB
   * Cebia certificate file relative path
   */
  cebiaCertUrl?: Maybe<Scalars['String']['output']>;
  cebiaReportDt?: Maybe<Scalars['Date']['output']>;
  /**  Number of stars. Availnable only if value is present and show_cebia_report = true. */
  cebiaReportResult?: Maybe<Scalars['Int']['output']>;
  cebiaSmartCodeUrl?: Maybe<Scalars['String']['output']>;
  color: CodeBookItem;
  condition?: Maybe<CodeBookItem>;
  crashed?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dealer?: Maybe<Dealer>;
  demoCarType?: Maybe<DemoCarType>;
  displayedInstallmentType?: Maybe<InstallmentType>;
  doorsCount?: Maybe<Scalars['Int']['output']>;
  economical?: Maybe<Scalars['Boolean']['output']>;
  emission?: Maybe<Scalars['Float']['output']>;
  /**  Unit is cm3 */
  engineCapacity: Scalars['Int']['output'];
  /**  Unit is kW */
  enginePower: Scalars['Int']['output'];
  equipmentItems?: Maybe<Array<CarEquipment>>;
  equipmentLevel?: Maybe<CodeBookItem>;
  evidenceNumber?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<File>>;
  financingInfo?: Maybe<Scalars['String']['output']>;
  financingPossible?: Maybe<Scalars['Boolean']['output']>;
  firstOwner?: Maybe<Scalars['Boolean']['output']>;
  firstRegistration?: Maybe<Scalars['Date']['output']>;
  fuel: CodeBookItem;
  fuelConsumptionCombined?: Maybe<Scalars['Float']['output']>;
  hasServiceBook?: Maybe<Scalars['Boolean']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /**  Order of images is driven by ISOV atttribute order. Currently needed only for recomanded models section. */
  images?: Maybe<Array<Image>>;
  instalment?: Maybe<Scalars['Float']['output']>;
  insuranceInfo?: Maybe<Scalars['String']['output']>;
  length?: Maybe<Scalars['Int']['output']>;
  manufactureYear?: Maybe<Scalars['Int']['output']>;
  mileage?: Maybe<Scalars['Int']['output']>;
  model: Model;
  modelType?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  motorType?: Maybe<CodeBookItem>;
  note?: Maybe<Scalars['String']['output']>;
  oneYearCar?: Maybe<Scalars['Boolean']['output']>;
  origin?: Maybe<CarOrigin>;
  originalPrice?: Maybe<Scalars['Int']['output']>;
  paintType?: Maybe<PaintType>;
  prettyUrl?: Maybe<Scalars['String']['output']>;
  price: Price;
  rentPrice?: Maybe<Scalars['Int']['output']>;
  reserved?: Maybe<Scalars['Boolean']['output']>;
  seatsCount?: Maybe<Scalars['Int']['output']>;
  serviceInspection?: Maybe<Scalars['Date']['output']>;
  showCalculator?: Maybe<Scalars['Boolean']['output']>;
  skodaPlus?: Maybe<Scalars['Boolean']['output']>;
  skofinOffers?: Maybe<Array<SkofinOffer>>;
  technicalInspection?: Maybe<Scalars['Date']['output']>;
  transmission: CodeBookItem;
  vatDeductible?: Maybe<Scalars['Boolean']['output']>;
  vin: Scalars['String']['output'];
  warrantiesInfo?: Maybe<Scalars['String']['output']>;
  warrantyTill?: Maybe<Scalars['Date']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
  youtubeId?: Maybe<Scalars['String']['output']>;
};


export type CarFinancingInfoArgs = {
  lang?: Lang;
};


export type CarImagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type CarInsuranceInfoArgs = {
  lang?: Lang;
};


export type CarNoteArgs = {
  lang?: Lang;
};


export type CarWarrantiesInfoArgs = {
  lang?: Lang;
};

export type CarConnection = {
  __typename?: 'CarConnection';
  edges?: Maybe<Array<Maybe<CarEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CarEdge = {
  __typename?: 'CarEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Car>;
};

export type CarEquipment = {
  __typename?: 'CarEquipment';
  category?: Maybe<CarEquipmentCategory>;
  highlighted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};


export type CarEquipmentNameArgs = {
  lang?: Lang;
};

export type CarEquipmentCategory = {
  __typename?: 'CarEquipmentCategory';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};


export type CarEquipmentCategoryNameArgs = {
  lang?: Lang;
};

export type CarEquipmentFilterItemOption = {
  __typename?: 'CarEquipmentFilterItemOption';
  id: Scalars['ID']['output'];
  isSelected: Scalars['Boolean']['output'];
  value?: Maybe<Scalars['String']['output']>;
};


export type CarEquipmentFilterItemOptionValueArgs = {
  lang?: Lang;
};

export type CarEquipmentItem = {
  __typename?: 'CarEquipmentItem';
  category?: Maybe<CarEquipmentCategory>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};


export type CarEquipmentItemNameArgs = {
  lang?: Lang;
};

export type CarFilterInput = {
  bodyTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  carsOrderBy?: InputMaybe<CarsOrderBy>;
  colors?: InputMaybe<Array<Scalars['ID']['input']>>;
  dealers?: InputMaybe<Array<Scalars['ID']['input']>>;
  demoCarTypes?: InputMaybe<Array<InputMaybe<DemoCarTypeInput>>>;
  doorsCountRange?: InputMaybe<InputIntRange>;
  engineCapacity?: InputMaybe<InputIntRange>;
  enginePower?: InputMaybe<InputIntRange>;
  equipmentItems?: InputMaybe<Array<Scalars['ID']['input']>>;
  equipmentLevels?: InputMaybe<Array<Scalars['ID']['input']>>;
  firstOwner?: InputMaybe<Scalars['Boolean']['input']>;
  firstRegistration?: InputMaybe<InputIntRange>;
  fuelTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  hasServiceBook?: InputMaybe<Scalars['Boolean']['input']>;
  instalmentRange?: InputMaybe<InputIntRange>;
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  make?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  metallicPaint?: InputMaybe<Scalars['Boolean']['input']>;
  mileageRange?: InputMaybe<InputIntRange>;
  model?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  oneYearCar?: InputMaybe<Scalars['Boolean']['input']>;
  priceRange?: InputMaybe<InputIntRange>;
  regionCountry?: InputMaybe<RegionCountry>;
  regions?: InputMaybe<Array<Scalars['ID']['input']>>;
  seatsCountRange?: InputMaybe<InputIntRange>;
  showOnHomepage?: InputMaybe<Scalars['Boolean']['input']>;
  skodaPlus?: InputMaybe<Scalars['Boolean']['input']>;
  skofinOffers?: InputMaybe<Array<Scalars['ID']['input']>>;
  transmissionTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  usedCar?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CarOrigin {
  Cz = 'CZ',
  Home = 'HOME',
  Import = 'IMPORT'
}

export type CarsCount = {
  carsCount: Scalars['Int']['output'];
};

export enum CarsOrderBy {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  FirstRegistrationAsc = 'FIRST_REGISTRATION_ASC',
  FirstRegistrationDesc = 'FIRST_REGISTRATION_DESC',
  MileageAsc = 'MILEAGE_ASC',
  MileageDesc = 'MILEAGE_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC'
}

/**  Contains settings for particular client like main car make and definition of pseudo body types (body type groups) */
export type ClientProfile = {
  __typename?: 'ClientProfile';
  bodyTypeGroups?: Maybe<Array<BodyTypeGroup>>;
  closeDealerRange: Scalars['Int']['output'];
  mainCarMake: Make;
  minImageSize: Scalars['String']['output'];
  preferredCarMakes?: Maybe<Array<Scalars['String']['output']>>;
  purposeFilterConfig?: Maybe<Array<FilterConfig>>;
};

export type CodeBookItem = CarsCount & {
  __typename?: 'CodeBookItem';
  carsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  type: CodeBookName;
  value?: Maybe<Scalars['String']['output']>;
};


export type CodeBookItemValueArgs = {
  lang?: Lang;
};

export enum CodeBookName {
  BodyType = 'BODY_TYPE',
  CarCondition = 'CAR_CONDITION',
  Color = 'COLOR',
  EquipmentLevel = 'EQUIPMENT_LEVEL',
  FuelType = 'FUEL_TYPE',
  Transmission = 'TRANSMISSION'
}

export type CodebookFilterItemOption = {
  __typename?: 'CodebookFilterItemOption';
  isSelected: Scalars['Boolean']['output'];
  value: CodeBookItem;
};

export enum ContactType {
  Email = 'EMAIL',
  Fax = 'FAX',
  MobilePhone = 'MOBILE_PHONE',
  Phone = 'PHONE'
}

export type Dealer = CarsCount & {
  __typename?: 'Dealer';
  about?: Maybe<Scalars['String']['output']>;
  address: Address;
  carsCount: Scalars['Int']['output'];
  dealerMicrositeId: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  gps?: Maybe<GpsCoords>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Image>>;
  isMother?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  openingHours?: Maybe<OpeningHours>;
  openingHoursComment?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Array<Maybe<DealerContact>>>;
  purchasingCars: Scalars['Boolean']['output'];
  sellers?: Maybe<Array<Seller>>;
  website?: Maybe<Scalars['String']['output']>;
};


export type DealerAboutArgs = {
  lang?: Lang;
};


export type DealerOpeningHoursCommentArgs = {
  lang?: Lang;
};

export type DealerContact = {
  __typename?: 'DealerContact';
  type: ContactType;
  value?: Maybe<Scalars['String']['output']>;
};

export enum DemoCarType {
  ForSale = 'FOR_SALE',
  OnRequest = 'ON_REQUEST'
}

export enum DemoCarTypeInput {
  Empty = 'EMPTY',
  ForSale = 'FOR_SALE',
  OnRequest = 'ON_REQUEST'
}

/**  Container for file. */
export type File = {
  __typename?: 'File';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/**  Definition of composite named filter. */
export type FilterConfig = {
  __typename?: 'FilterConfig';
  itemsConfig?: Maybe<Array<FilterItemConfig>>;
  name: Scalars['String']['output'];
};

/**  Configuration of single filter item (input). */
export type FilterItemConfig = {
  __typename?: 'FilterItemConfig';
  name: Scalars['String']['output'];
  options?: Maybe<Array<Maybe<FilterItemOption>>>;
};

export type FilterItemOption = CarEquipmentFilterItemOption | CodebookFilterItemOption | IntRangeFilterItemOption;

export type GpsCoords = {
  __typename?: 'GpsCoords';
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
};

/**  Container for image. Allows get image in several sizes. */
export type Image = {
  __typename?: 'Image';
  bigUrl: Scalars['String']['output'];
  normalUrl: Scalars['String']['output'];
  panorama: Scalars['Boolean']['output'];
  thumbnailUrl: Scalars['String']['output'];
};

export type InputIntRange = {
  max?: InputMaybe<Scalars['Int']['input']>;
  min?: InputMaybe<Scalars['Int']['input']>;
};

export enum InstallmentType {
  Daily = 'DAILY',
  Monthly = 'MONTHLY'
}

export type IntRangeFilterItemOption = {
  __typename?: 'IntRangeFilterItemOption';
  isSelected: Scalars['Boolean']['output'];
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
};

export enum Lang {
  Cs = 'CS',
  En = 'EN',
  Sk = 'SK'
}

export type Make = CarsCount & {
  __typename?: 'Make';
  carsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/**  Model images are part of client application */
export type Model = CarsCount & {
  __typename?: 'Model';
  carMake: Make;
  carsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  modelName: Scalars['String']['output'];
};

export type OpeningHours = {
  __typename?: 'OpeningHours';
  friday?: Maybe<Scalars['String']['output']>;
  monday?: Maybe<Scalars['String']['output']>;
  saturday?: Maybe<Scalars['String']['output']>;
  sunday?: Maybe<Scalars['String']['output']>;
  thursday?: Maybe<Scalars['String']['output']>;
  tuesday?: Maybe<Scalars['String']['output']>;
  wednesday?: Maybe<Scalars['String']['output']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum PaintType {
  Basic = 'BASIC',
  Metallic = 'METALLIC',
  Pearl = 'PEARL'
}

export type Price = {
  __typename?: 'Price';
  exclusiveOfVat?: Maybe<Scalars['Int']['output']>;
  priceAfterDiscount?: Maybe<Scalars['Int']['output']>;
  priceAfterDiscountExclusiveOfVat?: Maybe<Scalars['Int']['output']>;
  value: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  /**  Returns car by ISOV ID or null if no such car exists */
  car?: Maybe<Car>;
  /**  returns all body types for particular make and model */
  carBodyTypes: Array<BodyType>;
  /**
   * Returns all car makes which have at least one car in stock according to specified filter.
   * if you also want cars that are not in stock - set includeUnused to true
   * !!IF FETCH ALL IS SET TO TRUE -> THE RESULT WILL BE FILTERED JUST ACCORDING TO CAR MODELS
   */
  carMakes: Array<Make>;
  /**
   * Returns all car models which have at least one car in stock according to specified filter.
   * if you also want models whose cars are not in stock - set includeUnused to true
   * !!IF FETCH ALL IS SET TO TRUE -> THE RESULT WILL BE FILTERED JUST ACCORDING TO CAR MAKES
   * cars count in nested 'make' object is always -1
   */
  carModels: Array<Model>;
  /**  Currently used for fetch data for section recommended cars */
  cars: CarConnection;
  /**  Get number of cars accordidng provided filter critera */
  carsCount?: Maybe<Scalars['Int']['output']>;
  /**  Provides client configuration. Client ID is unique string like "skoda-plus". */
  clientProfile: ClientProfile;
  /**
   *  Returns all codebook items by specified name and in specified language which
   * have at least one car in stock according to specified filter.
   *  if you also want all codebook items(even if they have no cars in stock) - set includeUnused to true
   *  !!IF FETCH ALL IS SET TO TRUE -> THE RESULT WONT BE FILTERED ACCORDING TO PROVIDED FILTER
   */
  codeBook?: Maybe<Array<Maybe<CodeBookItem>>>;
  /**  Returns dealer by ISOV ID or null if no such car exists */
  dealer?: Maybe<Dealer>;
  /**
   * Returns all dealers which have at least one car in stock according to specified filter.
   * if you also want all dealers(even if they have no cars in stock) - set includeUnused to true
   * !!IF FETCH ALL IS SET TO TRUE -> THE RESULT WONT BE FILTERED ACCORDING TO PROVIDED FILTER
   */
  dealers?: Maybe<Array<Dealer>>;
  /**  Returns all car equipment items */
  equipmentItems?: Maybe<Array<CarEquipmentItem>>;
};


export type QueryCarArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCarBodyTypesArgs = {
  make: Array<Scalars['ID']['input']>;
  model?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryCarMakesArgs = {
  filter?: InputMaybe<CarFilterInput>;
  includeUnused?: Scalars['Boolean']['input'];
};


export type QueryCarModelsArgs = {
  filter?: InputMaybe<CarFilterInput>;
  includeUnused?: Scalars['Boolean']['input'];
};


export type QueryCarsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CarFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCarsCountArgs = {
  filter?: InputMaybe<CarFilterInput>;
};


export type QueryClientProfileArgs = {
  clientId: Scalars['String']['input'];
};


export type QueryCodeBookArgs = {
  codeBookName: CodeBookName;
  filter?: InputMaybe<CarFilterInput>;
  includeUnused?: Scalars['Boolean']['input'];
};


export type QueryDealerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDealersArgs = {
  filter?: InputMaybe<CarFilterInput>;
  includeMothers?: Scalars['Boolean']['input'];
  includeUnused?: Scalars['Boolean']['input'];
  purchasingCars?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum RegionCountry {
  Cz = 'CZ',
  Sk = 'SK'
}

export type Seller = {
  __typename?: 'Seller';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  jobPosition?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Image>;
  surname?: Maybe<Scalars['String']['output']>;
};


export type SellerJobPositionArgs = {
  lang?: Lang;
};

export type SkofinOffer = {
  __typename?: 'SkofinOffer';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};


export type SkofinOfferDescriptionArgs = {
  lang?: Lang;
};


export type SkofinOfferValueArgs = {
  lang?: Lang;
};
